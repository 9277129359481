/*===================================
=                                   =
=  - !Hello There                   =
=  - this is Your working space     =
=  - here you can customize phenix  =
=  - to your app ui design needs    =
=  - Good Luck :)                   =
=                                   =
===================================*/

/*====> Import Phenix <====*/
import Phenix from '.';
declare var PDS_WP_KEY: any;

//======> D.O.M is Ready [for you] <=======//
Phenix(document).ready((event:any) => {});